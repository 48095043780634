import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const { items: data } = useSelector((state) => state.category);
  const { items: footerData } = useSelector((state) => state.footerDetails);
  const { items: logo } = useSelector((state) => state.logo);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#06b6d4" : null,
    };
  };
  return (
    <div className="  bg-[#252524] text-slate-50 md:px-10 md:py-8 p-4 text-white">
      <div className="container mx-auto gap-5 grid lg:grid-cols-5">
        <div className="lg:col-span-2">
          <span className="font-light gap-3 flex flex-col   2xl:flex-row lg:flex-col  justify-between  text-sm">
            <span className="flex flex-col mb-5">
              <h4 className="mb-2">Pet Mart Online BD</h4>

              <p>
                Pet Mart Online BD: Where pets are family! Explore our curated
                supplies for your beloved companions, redefining pet care with
                quality and care. Beyond products, we're your partners in pet
                parenthood, committed to exceptional service. Join us for
                extraordinary moments, making every day special with Pet Mart
                Online BD. Our mission is to enhance the well-being of your
                furry friends, ensuring that your pet parenting journey is
                joyful and stress-free. Choose Pet Mart Online BD for a unique
                blend of high-quality products and personalized support. Your
                pets deserve the best, and we're here to make it happen. Welcome
                to the Pet Mart Online BD family, where every moment with your
                pets is extraordinary!
              </p>
            </span>
          </span>
          <img
            src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
            className="w-[14rem] mb-5"
            alt=""
          />

          <div className="flex  items-center gap-4">
            <h2 className="text-md font-light">Follow Us</h2>
            <div className="flex items-center">
              {/* <Link
                href={`https://www.facebook.com/NikoPetmart?mibextid=ZbWKwL`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 mr-4"
              >
                <FaFacebook />
              </Link> */}
              <a
                href="https://www.facebook.com/khilgaonpetfoodshop"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-blue-400 mr-4"
              >
                <FaFacebook />
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:hover:text-blue-400 mr-4"
              >
                <FaTwitter />
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-red-400"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col ">
          <h2 className="text-md mb-2 text-[#e7e4e2] ">Useful Links</h2>
          <NavLink
            style={navActive}
            to={"/"}
            end
            className=" inline-block text-sm font-light text-slate-50 "
          >
            Home
          </NavLink>
          {data?.slice(0, 5).map((navItem) => (
            <NavLink
              style={navActive}
              to={`/categorypage/${navItem.id}`}
              key={navItem.id}
              className="text-slate-50 text-sm  font-light"
            >
              {navItem.name}
            </NavLink>
          ))}
        </div>
        <div className="flex flex-col ">
          <h2 className="text-md mb-2 text-[#e7e4e2]  ">Company</h2>

          {footerData?.slice(0, 5).map((navItem) => (
            <NavLink
              style={navActive}
              to={`/${navItem.slug}/${navItem.id}`}
              key={navItem.id}
              className="text-slate-50 text-sm  font-light"
            >
              {navItem.title}
            </NavLink>
          ))}
        </div>
        <div className="flex flex-col ">
          <h2 className="text-md mb-2 text-[#e7e4e2] ">Contact info</h2>
          <Link className=" inline-block text-sm font-light text-slate-50 ">
            1495/a khilgaon,East side of Cumilla Hotel,khilgaon,Dhaka-1219
          </Link>

          <a href="tel://+8801790226230">+8801790226230</a>
          <Link
            className=" inline-block text-sm font-light text-slat NavLink
            "
          >
            Email: khilgaonpetshop@gmail.com
          </Link>
        </div>
      </div>

      <footer className="footer container mx-auto flex items-center justify-between    gap-5  text-slate-50 ">
        <div className="flex justify-center">
          <p className="text-slate-50 text-sm font-light mt-5">
            &copy; {new Date().getFullYear()} Pet Mart Online BD. All rights
            reserved.
          </p>
        </div>
        <div className="image-container w-72 2xl:w-[30rem] ">
          <img
            src="https://www.old.mehendibymimi.com/public/uploads/all/lnfIO3PdB1OE45OgRLmHu8fZdLRselIYRdKpAyam.png"
            alt=""
          />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
