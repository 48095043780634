import { useEffect, useState } from "react";

import SectionTitle from "../components/SectionTitle";
import { useParams } from "react-router-dom";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { singleCategoryFetching } from "../features/category/singleCategorySlice";

const CategoryPage = () => {
  const dispatch = useDispatch();

  const { items: data } = useSelector((state) => state.categoryProduct);

  const { id } = useParams();

  useEffect(() => {
    dispatch(singleCategoryFetching(id));
    // dispatch(thumbImageFetching(id));
  }, [dispatch, id]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_CATEGORY_URL}/${id}`);
  //       const data = await res.json();
  //       setCategoryProduct(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchData();
  // }, [id]);
  return (
    <div className="px-3 container mx-auto min-h-screen">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 mt-10 gap-2 md:gap-5">
        {data.length > 0 ? (
          data?.map((product) => (
            <div key={product.id} className="overflow-hidden">
              <Card product={product} />
            </div>
          ))
        ) : (
          <>
            <div>
              <span>There is no product in this Category</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
