import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import OrganicMehendi from "./pages/OrganicMehendi";
import NailMehendi from "./pages/NailMehendi";

import AddToCart from "./pages/AddToCart";

import ScrollToTop from "./components/ScrollToTop";
import OrganicHennaPowder from "./pages/OrganicHennaPowder";
import Checkout from "./pages/Checkout";
import { useSelector } from "react-redux";
import SuccessPage from "./pages/SuccessPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IndianMehendi from "./pages/IndianMehendi";
import MehendiArt from "./pages/MehendiArt";
import { useState } from "react";
import CartButton from "./components/CardButton";
import AddToCartCom from "./components/AddToCartCom";

import CategoryPage from "./pages/CategoryPage";
import SideBar from "./components/SideBar";
import MobileNav from "./components/MobileNav";
import SubCategoryPage from "./pages/SubCategoryPage";
import Pages from "./pages/Pages";
import NotFound from "./pages/NotFound";

function App() {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const { items: footerData } = useSelector((state) => state.footerDetails);
  // const [loading, setLoading] = useState(false);

  const [state, setState] = useState(false);

  const [sideNav, setSideNav] = useState(false);

  const handleAddToCart = () => {
    setState(!state);
  };

  const handleClose = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    setSideNav(() => !sideNav);
  };
  const handleMenu = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setSideNav(() => !sideNav);
  };

  return (
    <>
      {sideNav && (
        <>
          <div
            onClick={handleClose}
            className="bg-black/40   z-[9999]  fixed top-0 left-0 right-0 bottom-0 w-full h-full"
          ></div>
          <div className="flex justify-center items-center min-w-full">
            <SideBar handleClose={handleClose} />
          </div>
        </>
      )}
      <div className=" bg-[#F2F3F8] ">
        <div className="min-h-screen">
          <Navbar handleMenu={handleMenu} />
          <ToastContainer />
          <ScrollToTop>
            <Routes>
              <Route path={"/"} element={<Home />} />
              <Route path="/productdetails" element={<ProductDetails />} />
              <Route
                exact
                path="/productdetails/:id"
                element={<ProductDetails />}
              />
              <Route exact path="/laptop/:id" element={<OrganicMehendi />} />
              <Route exact path="/desktop/:id" element={<NailMehendi />} />
              <Route
                exact
                path="/smart-watch/:id"
                element={<IndianMehendi />}
              />
              <Route path="monitor/:id" element={<OrganicHennaPowder />} />
              {/* <Route path="/mehendi-review/:id" element={<MehendiReview />} /> */}
              <Route path="/accessories/:id" element={<MehendiArt />} />
              <Route path="/subcategory" element={<SubCategoryPage />} />
              <Route path="/subcategory/:id" element={<SubCategoryPage />} />
              <Route path="/categorypage" element={<CategoryPage />} />
              <Route path="/categorypage/:id" element={<CategoryPage />} />
              <Route path="/addtocart" element={<AddToCart />} />
              <Route
                path="/checkout"
                element={data?.length > 0 ? <Checkout /> : <NotFound />}
              />
              <Route path="/successpage" element={<SuccessPage />} />
              {footerData.map((footer) => (
                <>
                  <Route path={`/${footer.slug}/:id`} element={<Pages />} />
                </>
              ))}
            </Routes>

            <button
              onClick={handleAddToCart}
              className="fixed top-[45%] right-0 z-[9999]"
            >
              <CartButton data={data} cartTotalAmount={cartTotalAmount} />
            </button>
            {state && <AddToCartCom state={state} setState={setState} />}
          </ScrollToTop>
        </div>

        <MobileNav handleMenu={handleMenu} />
        <Footer />
      </div>
    </>
  );
}

export default App;
