import SectionTitle from "./SectionTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryCard = () => {
  const { items: data } = useSelector((state) => state.category);

  return (
    <div className="wrapper">
      <SectionTitle title={"Categories"} />
      <div className="w-full flex flex-wrap justify-center gap-2">
        {data?.map((category) => (
          <Link
            to={`/categorypage/${category.id}`}
            className="category-card w-[100px] md:w-[120px] 2xl:w-[140px] p-2 hover:shadow-md  duration-300  rounded-md  h-full bg-white border border-r border-[#e2e2e2] flex flex-col"
            key={category.id}
          >
            <div className="flex items-center justify-center">
              <div className="category_image_container w-[80px] h-[80px] md:h-[100px] md:w-[100px] lg:h-[100px] lg:w-[100px] overflow-hidden flex items-center justify-center mt-3">
                <img
                  src={`${process.env.REACT_APP_URL}/uploads/category/${category?.banner}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="mt-[10px] text-center text-[#212121] truncate">
              {category.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;
